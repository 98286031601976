body,
#root,
html,
#wrap {
}

#wrap {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    background-attachment: fixed !important;
    background-image: url('/images/v2/map.svg');
}

footer {
    background-color: #ffffff;
}

@media screen and (max-width: 960px) {
    body,
    #root,
    html,
    #wrap {
        background: #fff !important;
        background-color: #fff !important;
    }
}

